var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"dash-flex-button-container"}},[_c('div',{attrs:{"id":"navi"}},[(_vm.showInviteCitizenSpinner)?[_c('div',{staticClass:"q-loader2"})]:_vm._e(),(_vm.reception)?_c('b-button',{staticClass:"btn-primary mr-1",attrs:{"disabled":_vm.citizenInvited ||
        _vm.showTimeTrackingIcon ||
        _vm.performingAction ||
        _vm.showAdmin,"id":"invite-citizen-button"},on:{"click":_vm.invite}},[_vm._v("Invite")]):_vm._e(),_c('b-button',{class:_vm.serveNowStyle,attrs:{"disabled":_vm.citizenInvited === false,"id":"serve-citizen-button"},on:{"click":_vm.clickServeNow}},[_vm._v("Serve Now")])],2),_c('div',[_c('b-button-group',[_c('b-button',{staticClass:"btn-primary ml-2",attrs:{"disabled":_vm.citizenInvited ||
          _vm.showTimeTrackingIcon ||
          _vm.performingAction ||
          _vm.showAdmin,"id":"add-citizen-button"},on:{"click":_vm.addCitizen}},[_vm._v("Add Citizen")]),(_vm.quickListItems.length > 0)?_c('b-dropdown',{attrs:{"variant":_vm.citizenInvited === true || _vm.performingAction || _vm.showAdmin
            ? ''
            : 'primary',"disabled":_vm.citizenInvited ||
          _vm.showTimeTrackingIcon ||
          _vm.performingAction ||
          _vm.showAdmin,"right":""}},_vm._l((_vm.quickListItems),function(item){return _c('b-dropdown-item',{key:item.service_id,attrs:{"data-id":item.service_id},on:{"click":_vm.quickServeCitizen}},[_vm._v(_vm._s(item.service_name))])}),1):_vm._e()],1),_c('b-button-group',[_c('b-button',{staticClass:"btn-primary ml-2",attrs:{"disabled":_vm.citizenInvited ||
          _vm.showTimeTrackingIcon ||
          _vm.performingAction ||
          _vm.showAdmin,"id":"add-citizen-button"},on:{"click":_vm.clickBackOffice}},[_vm._v("Back Office")]),(_vm.backOfficeItems.length > 0)?_c('b-dropdown',{attrs:{"variant":_vm.citizenInvited === true || _vm.performingAction || _vm.showAdmin
            ? ''
            : 'primary',"disabled":_vm.citizenInvited ||
          _vm.showTimeTrackingIcon ||
          _vm.performingAction ||
          _vm.showAdmin,"right":""}},_vm._l((_vm.backOfficeItems),function(item){return _c('b-dropdown-item',{key:item.service_id,attrs:{"data-id":item.service_id},on:{"click":_vm.quickBackOffice}},[_vm._v(_vm._s(item.service_name))])}),1):_vm._e()],1)],1),_c('div')])
}
var staticRenderFns = []

export { render, staticRenderFns }
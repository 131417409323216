import { render, staticRenderFns } from "./office-drop.vue?vue&type=template&id=5468a401&scoped=true"
import script from "./office-drop.vue?vue&type=script&lang=ts"
export * from "./office-drop.vue?vue&type=script&lang=ts"
import style0 from "./office-drop.vue?vue&type=style&index=0&id=5468a401&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5468a401",
  null
  
)

export default component.exports